.modal-button {
	display: inline-block;
	width: 165px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 25px;
	color: #282828;
	transition: 0.5s ease-in;
	font-weight: 600;
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
	border: 2px solid #197beb;
}
.modal-button:hover {
	box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.35);
	background-color: #197beb;
	color: #fff;
}
