* {
	box-sizing: border-box;
}

.row1 {
	display: -ms-flexbox; /* IE10 */
	display: flex;
	-ms-flex-wrap: wrap; /* IE10 */
	flex-wrap: wrap;
	margin: 0 -16px;
}

.col1-25 {
	-ms-flex: 25%; /* IE10 */
	flex: 25%;
}

.col1-50 {
	-ms-flex: 50%; /* IE10 */
	flex: 50%;
}

.col1-75 {
	-ms-flex: 75%; /* IE10 */
	flex: 75%;
}

.col1-25,
.col1-50,
.col1-75 {
	padding: 0 16px;
}

.container1 {
	background-color: #f2f2f2;
	padding: 5px 20px 15px 20px;
	border: 1px solid lightgrey;
	border-radius: 3px;
}

input[type="text"] {
	width: 100%;
	margin-bottom: 0px;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.label1 {
	margin-bottom: 10px;
	display: block;
}

.icon-container1 {
	margin-bottom: 20px;
	padding: 7px 0;
	font-size: 24px;
}

.btn1 {
	background-color: #4caf50;
	color: white;
	padding: 12px;
	margin: 10px 0;
	border: none;
	width: 100%;
	border-radius: 3px;
	cursor: pointer;
	font-size: 17px;
}

.btn1:hover {
	background-color: #45a049;
}

a {
	color: #2196f3;
}

hr {
	border: 1px solid lightgrey;
}

span.price {
	float: right;
	color: grey;
}

div.modal-content{
  width:152%
}
