.col-md-2 {
  margin: auto;
}
.select{
  padding: 0px 8px !important;
}

.multiselect-container.multiSelectContainer input{
  padding: 0px 8px !important;

}