

.iphone {
  background-color: #F7F7F7;
  margin: 10px;
  height: 450px;
  width: 375px;
  box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  transform: scale(0.9);
  transform-origin: top center;
}

.header {
  height: 30%;
  background-color: #44B0A0;
  border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 1;
   overflow: hidden;
}

.header::before{
  content:"";
  height: 200px;
  width: 200px;
  background-color: #53b5a8;
  border-radius: 50%;
  position:absolute;
  top: -60px;
  left: -100px;
  z-index: 2;
}

.header::after {
  content:"";
  height: 100px;
  width: 100px;
  background-color: #53b5a8;
  border-radius: 50%;
  position:absolute;
  right: -20px;
  bottom: -30px;
  z-index: 2;
 
}

.header-summary {
  display: flex;
  flex-direction: column;
font-family: 'Montserrat', sans-serif;
  color: white;
  z-index: 3;
}

.summary-text {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.summary-balance {
  font-size: 2rem;
  letter-spacing: 1.5px;
  margin-bottom: 0.8rem;
  
}

.summary-text-2 {
  font-size: 0.8rem;
  letter-spacing: 1px;
}


.content {
  z-index: 4;
  /* position: absolute; */
  top: 210px;
  margin-left:30px;
  display:flex;
  flex-direction:column;
  width:85%;
}
@media only screen and (max-width: 600px) {
.content {
  z-index: 4;
  /* position: absolute; */
  top: 210px;
  margin-left:30px;
  display:flex;
  flex-direction:column;
  width:85%;
}
.iphone {
  background-color: #F7F7F7;
  margin: 10px;
  height: 450px;
  width:300px;
  box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  transform: scale(0.9);
  transform-origin: top center;
}
.con{
  padding-top: 50px;
   padding-right: -10px;
}
}

.upper-row {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 25px;
  padding-top: 10px
}

.card-item {
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.card-item span:first-child {
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom:10px;
}

.card-item span:last-child {
  font-size: 1.2rem;
  font-weight: 300;
}

.dollar {
  color: #FFC168;
}

.lower-row {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.lower-row::before {
    content: '';
    display: block;
    position: absolute;
    top: 110px;
    width: 50%;
    left: 25%;
    border-top: 1px solid #E1E1E1;
}

.icon-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  color: #FFC168;
  padding: 10px;
  background-color: #FBFBFB;
  border-radius: 5px;
  margin-bottom: 5px;
}

.icon-text {
  font-size: 0.6em;
  color: #939393;
}

.transactions {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  position: relative;
  margin-top: 1.5rem;
  flex-direction: column;
}

.t-desc {
  font-size: 0.8rem;
  font-weight: bold;
  padding-bottom: 10px;
}

.transaction {
  cursor: default;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:white;
  box-shadow: 0 14px 28px rgba(0,0,0,0.02), 0 10px 10px rgba(0,0,0,0.0);
  border-radius: 12px;
  margin-bottom:20px;
}

.t-details {
  width: 55%;
}

.t-icon-container {
  width: 45px;
  align-self: flex-start;
}

.t-icon {
  width: 100%;
  border-radius: 50%;
  box-shadow: 5px 5px 25px 0px rgba(0,0,0,0.2);
}

.t-title {
  text-align: left;
  flex-grow: 1;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.t-time {
  font-size: 0.6rem;
  opacity: 0.6;
  font-weight: 300;
  letter-spacing: 1px;
}

.t-amount {
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: 400;
  color: #06D778;
}

.red {
  color: #F4532D;
}

.drawer {
  display:flex;
  background-color: white;
  height:80px;
  position:absolute;
  bottom: 0px;
  width: 100%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 5;
  justify-content: space-evenly;
  align-items: center;
}


.drawer span {
  display: inline-block;
  font-size: 1.5rem;
  color: #FFC168;
  transition: all 0.4s;
  cursor: pointer;
}

.drawer span i:hover {
  transform: scale(1.2);
  border-bottom: 2px solid orange;
}

.drawer span i {
  transition: all 0.3s ease;
  padding-bottom: 8px;
}



.menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #FFC168;
  color: white;
  top: -25px;
  position: absolute;
  transform: rotate(-45deg);
  border-radius: 10px;
  box-shadow: 5px 5px 25px 0px rgba(0,0,0,0.2);
  cursor: pointer;
  transition: all 0.1s;
  opacity: 0.9;
}

.menu-btn:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.menu-btn i{
  font-size: 1.5em;
  transform: rotate(45deg);
  
}


.transaction:hover {
    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.15);
}
