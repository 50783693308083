.containers {
	background-color: white;
	width: 275px;
	padding: 20px;
	margin: 10px;
	height: 450px;
	text-align: center;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media (min-width: 1200px) {
	.containers,
	.containers-lg,
	.containers-md,
	.containers-sm,
	.containers-xl {
		max-width: 1140px;
	}
}
.rows1 {
	margin: 10px;
	width: 280px;
	clear: both;
	text-align: center;
	font-family: "Exo";
}

.digit {
	float: left;
	padding: 10px 30px;
	width: 30px;
	font-size: 2rem;
	cursor: pointer;
}
.dig {
	float: left;
	padding: 10px 20px;
	margin: 2px;
	width: 30px;
	cursor: pointer;
}
.sub {
	font-size: 0.8rem;
	color: grey;
}

.container1 {
	background-color: white;
	width: 320px;
	padding: 20px;
	margin: 30px auto;
	height: 420px;
	text-align: center;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#output {
	font-family: "Exo";
	font-size: 2rem;
	height: 60px;
	font-weight: bold;
	color: #1976d2;
}

#call {
	display: inline-block;
	background-color: #66bb6a;
	padding: 4px 30px;
	margin: 10px;
	color: white;
	border-radius: 4px;
	float: left;
	cursor: pointer;
}
#discall {
	display: inline-block;
	background-color: #ff0000;
	padding: 4px 30px;
	margin: 10px;
	color: white;
	border-radius: 4px;
	float: left;
	cursor: pointer;
}

.botrow {
	margin: 0 auto;
	width: 280px;
	clear: both;
	text-align: center;
	font-family: "Exo";
}

.digit:active,
.dig:active {
	background-color: #e6e6e6;
}

#call:hover {
	background-color: #81c784;
}

/* .dig {
	float: left;
	padding: 10px 20px;
	margin: 10px;
	width: 30px;
	cursor: pointer;
} */
.btn.btn-big{
	color:white !important;
	background-color: #3f51b5 !important
}